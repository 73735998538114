import React from 'react';
import { withRouter } from 'react-router-dom';
import { setUserData, getUserData, userIsLogged } from '../../logic/user';
import { Redirect } from 'react-router';

import {
	Button,
	Alert,
	ControlLabel,
	FormControl,
	FormGroup,
} from 'react-bootstrap';
import {encodeEventLocalId} from "../../logic/misc";

export const UserRegister = withRouter(
	class extends React.Component {
		constructor(props) {
			super(props);

			const oldUserData = getUserData();

			this.state = {
				name: oldUserData?.name || '',
				surname: oldUserData?.surname || '',
				email: oldUserData?.email || '',
				title: oldUserData?.title || '',
				emailError: false,
				focus: null,
				sent: false,
			};
		}

		_setter = (event) => {
			this.setState({ [event.target.name]: event.target.value });
		};

		_save = () => {
			const { history, event } = this.props;
			const { email, name, surname, title } = this.state;
			const hash = encodeEventLocalId(event.localId);

			// controllo formato email
			const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			const emailIsValid = re.test(String(email).toLowerCase());

			if (!emailIsValid) this.setState({ emailError: true });
			else {
				setUserData({ name, surname, title, email });
				history.push(`/${hash}/search`);
			}
		};

		_inputOnFocus = (event) => {
			this.setState({
				focus: event.target.name,
			});
		};

		_inputOnBlur = (event) => {
			this.setState({
				focus: null,
			});
		};

		render() {
			const { event } = this.props;
			const { name, surname, email, title, emailError } = this.state;
			const hash = encodeEventLocalId(event.localId);
			const required = event.validation === 'Yes';
			const btnDisabled =
				required && (!name || !surname || !email || !title);

			if (userIsLogged())
				return <Redirect to={'/' + hash + '/search'} />;

			return (
				<React.StrictMode>
					<div className="page">
						<section className="userLogin">
							<div className="intro">
								<div className="video">
									<iframe
										src="https://player.vimeo.com/video/185806517?autoplay=1&amp;autopause=0&amp;loop=1&background=1"
										allowtransparency="true"
										allow="autoplay; fullscreen"
										frameBorder="0"
										width="100%"
										height="100%"
									/>
								</div>
								<div className="w">
									<div className="heading">
										<img src="/Showcase_logo_light.svg" />
										<h1>
											Welcome to
											<br />
											{event.name}
										</h1>
										{/*<p>
											Enjoy our tailor-made training session and
											product presentations.
										</p>*/}
									</div>
								</div>
							</div>
							<div className="bottomContents topCut">
								<div className="w loginForm">
									<h2>Register</h2>
									<div className="contents">
										<div className="formWrapper">
											<form
												onSubmit={(event) => {
													event.preventDefault();
													this._save();
												}}
											>
												<FormGroup
													className={
														name || this.state.focus == 'name'
															? 'compiled'
															: ''
													}
												>
													<ControlLabel>Name</ControlLabel>
													<FormControl
														tabIndex="1"
														type="text"
														value={name}
														name={'name'}
														onChange={this._setter}
														onFocus={this._inputOnFocus}
														onBlur={this._inputOnBlur}
													/>
												</FormGroup>
												<FormGroup
													className={
														surname ||
														this.state.focus == 'surname'
															? 'compiled'
															: ''
													}
												>
													<ControlLabel>Surname</ControlLabel>
													<FormControl
														tabIndex="2"
														type="text"
														value={surname}
														name={'surname'}
														onChange={this._setter}
														onFocus={this._inputOnFocus}
														onBlur={this._inputOnBlur}
													/>
												</FormGroup>
												<FormGroup
													className={
														title || this.state.focus == 'title'
															? 'compiled'
															: ''
													}
												>
													<ControlLabel>Title</ControlLabel>
													<FormControl
														tabIndex="3"
														type="text"
														value={title}
														name={'title'}
														onChange={this._setter}
														onFocus={this._inputOnFocus}
														onBlur={this._inputOnBlur}
													/>
												</FormGroup>
												<FormGroup
													className={
														email || this.state.focus == 'email'
															? 'compiled'
															: ''
													}
												>
													<ControlLabel>Email</ControlLabel>
													<FormControl
														tabIndex="4"
														type="email"
														value={email}
														name={'email'}
														onChange={this._setter}
														onFocus={this._inputOnFocus}
														onBlur={this._inputOnBlur}
													/>
												</FormGroup>
												<div className="form-last">
													{emailError && (
														<Alert
															bsStyle="danger"
															closeLabel={false}
														>
															<strong>Email not valid!</strong>
															<p>
																Please check the infos and try
																again
															</p>
														</Alert>
													)}
													<Button
														onClick={() => this._save()}
														disabled={btnDisabled}
													>
														Enter
													</Button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</React.StrictMode>
			);
		}
	},
);
