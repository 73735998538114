import React, { useState, useRef, useEffect } from 'react';
import { TweenLite, TimelineLite } from 'gsap/all';
import { resetUserData } from '../../logic/user';
import { Link } from 'react-router-dom';
import { encodeEventLocalId } from '../../logic/misc';

export const Header = ({
	showHome,
	showLogout,
	scrolledText = '',
	videoState = null,
	setVideoState,
	event,
	partnerLogo,
	scrollState,
	scrolledTextHeightItem = null,
	scrolledTextHeightLoaded = true,
}) => {
	const scrolledTextRef = useRef();
	const logoRef = useRef();
	const partnerRef = useRef();
	const logoWrapRef = useRef();
	const pauseIconCircle = useRef();
	const pauseIcon = useRef();

	const [headerShowingContent, setHeaderShowingContent] = useState('logo');
	const [scrolledTextHeightTrigger, setScrolledTextHeightTrigger] = useState(
		100,
	);
	const [videoIconState, setVideoIconState] = useState(false);

	const hash = encodeEventLocalId(event.localId);

	// scroll hook
	useEffect(() => {
		if (scrolledText !== '') {
			if (
				scrollState.y > scrolledTextHeightTrigger - 50 &&
				headerShowingContent === 'logo'
			) {
				setHeaderShowingContent('text');
			} else if (
				scrollState.y <= scrolledTextHeightTrigger - 50 &&
				headerShowingContent === 'text'
			) {
				setHeaderShowingContent('logo');
			}
		}
	}, [scrollState]);

	useEffect(() => {
		const time = 0.5;
		if (headerShowingContent === 'logo') {
			TweenLite.to(logoWrapRef.current, time, {
				y: '0%',
				opacity: 1,
				ease: 'power3.out',
			});
			TweenLite.to(scrolledTextRef.current, time, {
				y: '100%',
				opacity: 0,
				ease: 'power3.out',
			});
		} else {
			TweenLite.to(logoWrapRef.current, time, {
				y: '-100%',
				opacity: 0,
				ease: 'power3.out',
			});
			TweenLite.to(scrolledTextRef.current, time, {
				y: '0%',
				opacity: 1,
				ease: 'power3.out',
			});
		}
	}, [headerShowingContent, scrolledTextRef, logoWrapRef]);

	useEffect(() => {
		if (partnerRef.current && logoRef.current) {
			const tl = new TimelineLite({
				paused: false,
				repeat: -1,
				yoyo: 1,
				repeatDelay: 5,
				delay: 1,
			});
			tl.fromTo(
				logoRef.current,
				0.6,
				{
					opacity: 1,
					scale: 1,
				},
				{
					opacity: 0,
					scale: 0.8,
					ease: 'power3.out',
				},
			);
			tl.fromTo(
				partnerRef.current,
				0.6,
				{
					opacity: 0,
					scale: 0.8,
					yPercent: -50,
					xPercent: -50,
				},
				{
					opacity: 1,
					scale: 1,
					yPercent: -50,
					xPercent: -50,
					ease: 'power3.out',
				},
				0.3,
			);
		}
	}, [partnerRef, logoRef]);

	// resize effect
	useEffect(() => {
		// Add event listener
		const handleResize = () => {
			if (scrolledTextHeightItem) {
				setScrolledTextHeightTrigger(
					scrolledTextHeightItem.current.offsetHeight,
				);
			}
		};
		window.addEventListener('resize', handleResize);
		window.addEventListener('load', handleResize);
		handleResize();
		// Remove event listener on cleanup
		return () => {
			window.removeEventListener('resize', handleResize);
			window.removeEventListener('load', handleResize);
		};
	}, [scrolledTextHeightLoaded]);

	let videoPlaying = false;
	useEffect(() => {
		if (!videoState) return;
		const circle = pauseIconCircle.current;
		if (circle) {
			const radius = circle.r.baseVal.value;
			const circumference = radius * 2 * Math.PI;

			const offset =
				circumference - (videoState.percent / 100) * circumference;
			circle.style.strokeDashoffset = offset;
			circle.style.strokeDasharray = `${circumference} ${circumference}`;
		}
		if (videoState.videoPlaying != videoIconState) {
			if (videoState.videoPlaying === true) {
				// show icon
				TweenLite.to(pauseIcon.current, 0.7, {
					scale: 1,
					opacity: 1,
					ease: 'back.out(1)',
				});
			} else {
				// hide icon
				TweenLite.to(pauseIcon.current, 0.7, {
					scale: 0,
					opacity: 0,
					ease: 'back.in(1)',
				});
			}
			setVideoIconState(videoState.videoPlaying);
		}
	}, [videoState]);

	const searchUrl = `/${hash}/search/`;
	// const isInSearchPage = searchUrl ===

	return (
		<>
			<div id="fixedHeader">
				<div className="w">
					<div className="contents">
						<div className="col left">
							<div className="icon">
								{showHome ? (
									<Link to={`/${hash}`}>
										<img src="/icons/home.svg" />
									</Link>
								) : (
									''
								)}
							</div>
						</div>
						<div className="col center">
							<Link to={searchUrl}>
								<div className="img" ref={logoWrapRef}>
									<img src="/Showcase_logo.svg" ref={logoRef} />
									{partnerLogo ? (
										<img
											src={partnerLogo}
											className="partnerLogo"
											ref={partnerRef}
										/>
									) : (
										''
									)}
								</div>
							</Link>
							<span ref={scrolledTextRef}>{scrolledText}</span>
						</div>
						<div className="col right">
							{showLogout ? (
								<div className="icon">
									<Link
										to={`/${hash}`}
										onClick={() => resetUserData()}
									>
										<img src="/icons/logout.svg" />
									</Link>
								</div>
							) : (
								<div
									className="videoToggler"
									onClick={() => {
										setVideoState({
											...videoState,
											videoPlaying: false,
										});
									}}
								>
									<div className="icon pause" ref={pauseIcon}>
										<svg height="32" width="32" className="circle">
											<circle
												strokeWidth="1.5"
												stroke="#a8a8a8"
												fill="transparent"
												r="12"
												cx="16"
												cy="16"
											/>
											<circle
												ref={pauseIconCircle}
												strokeWidth="1.5"
												stroke="black"
												fill="transparent"
												r="12"
												cx="16"
												cy="16"
											/>
										</svg>
										<img src="/icons/pause.svg" />
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/*
VECCHIO METODO

export class Header extends React.Component {
	constructor(props) {
		super(props);

		const { showHome, showLogout } = this.props;

		this.state = {};
	}

	_setter = (fieldName) => (event) =>
		this.setState({ [fieldName]: event.target.value });

	render() {
		return <div id="fixedHeader">lorem ipsum</div>;
	}
}
*/
