import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loadDataSheet } from '../../logic/data';
import { TweenLite } from 'gsap/all';
import { Alert, Button, FormControl, FormGroup } from 'react-bootstrap';
import { getUserData, userIsLogged } from '../../logic/user';
import { Redirect } from 'react-router';
import { Header } from '../partials/Header';
import { OverlayIframe } from '../partials/OverlayIframe';
import { encodeEventLocalId } from '../../logic/misc';

export const ProductSearch = React.memo(({ event, scrollState }) => {
	// page state
	const [pageState, setPageState] = useState({
		loading: true,
		products: null,
		inputCode: '',
		notFoundError: false,
		foundProduct: false,
		iframeOverlayOpen: false,
	});

	const introTexts = useRef();
	const introSection = useRef();
	const hash = encodeEventLocalId(event.localId);

	useEffect(() => {
		if (introTexts.current && introSection.current) {
			const perc = (1 / introSection.current.offsetHeight) * scrollState.y;
			TweenLite.to(introTexts.current, 0.1, {
				opacity: 1 - perc,
				y: scrollState.y / 2,
			});
		}
	}, [scrollState]);

	// check if user is logged
	const isLogged = userIsLogged();
	if (!isLogged) return <Redirect to={'/' + hash + '/'} />;
	const userData = getUserData();

	// feedback form url
	const feedbackFormUrl = event.feedback
		? event.feedback
		: 'https://gruppofood.typeform.com/to/QDfvPA';

	// if it still has to load products from the csv
	if (pageState.products === null) {
		// csv filename
		const csvName = event.tableSheet;

		loadDataSheet(csvName).then((objs) => {
			// rimuovo il 99 che, storicamente, e' un codice interno, e ordino i prodotti in base al codice numerico
			const products = objs
				.filter((item) => item.code !== 99)
				.sort((itemA, itemB) => itemA.code - itemB.code);
			setPageState({ ...pageState, loading: false, products });
		});
	}

	// if loading
	if (pageState.loading) return <section>Loading...</section>;

	const search = () => {
		const product = pageState.products.find(
			(p) => p.code === pageState.inputCode,
		);

		if (product)
			setPageState({
				...pageState,
				foundProduct: `/${hash}/${pageState.inputCode}/`,
			});
		else setPageState({ ...pageState, notFoundError: true });
	};

	if (pageState.foundProduct) return <Redirect to={pageState.foundProduct} />;

	// render page
	return (
		<>
			<Header
				showHome={false}
				showLogout={true}
				scrolledText={`Welcome ${userData.name}`}
				partnerLogo={event.partnerLogo}
				scrollState={scrollState}
				event={event}
			/>
			<section className="productSearchIntro" ref={introSection}>
				<div className="w" ref={introTexts}>
					<h1>Welcome {userData.name}</h1>
					<p>{event.name}</p>
				</div>
			</section>
			{/*<section className="productSearch topCut">
				<div className="w">
					<h2 className="sectionTitle">Go to product</h2>
					<p>
						Insert the number associated with the product you're looking
						at
					</p>
					<form
						className="body"
						onSubmit={(event) => {
							event.preventDefault();
							search();
						}}
					>
						<FormGroup>
							<FormControl
								type="number"
								className="search-product-input text-center"
								value={pageState.inputCode}
								placeholder="00"
								onChange={(e) =>
									setPageState({
										...pageState,
										inputCode: e.target.value,
									})
								}
							/>
						</FormGroup>

						<div className="form-last">
							{pageState.notFoundError && (
								<Alert bsStyle="danger">
									<strong>Product not found!</strong>
									<p>Please check your code and try again</p>
								</Alert>
							)}
							<Button onClick={() => search()}>Go</Button>
						</div>
					</form>
				</div>
							</section>*/}
			<section className="productsList bgLightOrange">
				<div className="w">
					<h2 className="sectionTitle">Products</h2>
					<p>Lorem ipsum dolor sit amet? Consectetur adipiscing elit!</p>
					<ul>
						{pageState.products.map((item, i) => (
							<li key={i}>
								<Link to={`/${hash}/${item.code}`}>
									<span className="code">{item.code}</span>
									<span className="texts">
										<span className="productName">
											{item.productName}
										</span>
										<span
											className="categoryName"
											style={{
												color: 'rgb(243, 146, 0)',
											}}
										>
											{item.categoryName}
										</span>
									</span>
								</Link>
							</li>
						))}
					</ul>
				</div>
			</section>

			{feedbackFormUrl !== null ? (
				<>
					<section
						className="productFeedback topCut"
						data-sectionname="productFeedback"
					>
						<div className="w">
							<h2 className="sectionTitle">Feedback</h2>
							<p>
								Your opinion is important to us. Help us to better
								understand Your needs and improve this ShowCase
								experience.
							</p>
							<div className="ctas">
								<div
									className="btn"
									onClick={() => {
										setPageState({
											...pageState,
											iframeOverlayOpen: true,
										});
									}}
								>
									Have your say!
								</div>
							</div>
						</div>
					</section>
					<OverlayIframe
						pageState={pageState}
						setPageState={setPageState}
						url={feedbackFormUrl}
						dataQuerystring={{
							first_name: userData.name,
							last_name: userData.surname,
							email: userData.email,
							title: userData.title,
						}}
					/>
				</>
			) : (
				''
			)}
		</>
	);
});
