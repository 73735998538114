// noinspection JSUnresolvedFunction
const parse = require('csv-parse/lib/es5/sync');

async function loadCSV(csv = 'master-H1.csv') {
	const res = await fetch('/csv/' + csv);
	const content = await res.text();

	return parse(content, { columns: true }).map(Object.values);
}

const cache = {};

async function loadDataSheet(name) {
	if (!cache[name]) {
		const csv = await loadCSV(name);
		cache[name] = csv.map((line) => ({
			code: line[0],
			productName: line[1],
			companyName: line[2],
			companyDescription: line[3],
			extUrl: line[4],
			categoryName: line[5],
			productImage: line[6],
			sellingUnit: line[7],
			itemsPerFormat: line[8],
			sellerPricePerItem: line[9],
			sellerPricePerKG: line[10],
			priceStandards: line[11],
			markup: line[12],
			images: line[13],
			typeformUrl: line[14],
			videos: line[15],
			faqs: line[16],
			askCompanyUrl: line[17],
		}));
	}

	return cache[name];
}

export { loadCSV, cache, loadDataSheet };
