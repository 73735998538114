import React from 'react';
import { withRouter } from 'react-router-dom';

export const Page = withRouter(
	class extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				x: 0,
				y: 0,
			};
		}

		componentDidUpdate(prevProps) {}

		componentDidMount() {
			window.scrollTo(0, 0);
			/*
			function handleResize() {
				document.documentElement.style.setProperty(
					'--realDynamic100vh',
					window.innerHeight + 'px',
				);
			}
			handleResize();
			
			window.addEventListener('resize', handleResize);*/
			document.addEventListener('scroll', () => this.pageScrolled());
		}

		pageScrolled() {
			const Y = window.pageYOffset || document.documentElement.scrollTop;
			const X = window.pageXOffset || document.documentElement.scrollLeft;
			this.setState({ x: X, y: Y });
		}

		render() {
			return (
				<div className={'page ' + this.props.id}>
					{React.Children.map(this.props.children, (child) =>
						React.cloneElement(child, { scrollState: this.state }),
					)}
				</div>
			);
		}
	},
);
