const userRegistrationDataKey = 'USER_DATA_KEY';
const userLoggedKey = 'USER_LOGGED_KEY';

function resetUserData() {
	localStorage.removeItem(userLoggedKey);
}

function setUserData(obj) {
	localStorage.setItem(userRegistrationDataKey, JSON.stringify(obj));
	localStorage.setItem(userLoggedKey, 'true');
}

function getUserData() {
	return JSON.parse(localStorage.getItem(userRegistrationDataKey));
}

function userIsLogged() {
	return !!localStorage.getItem(userLoggedKey);
}

export { resetUserData, setUserData, getUserData, userIsLogged };
