import React, { Component } from 'react';
import '../scss/App.scss';
import { loadCSV } from '../logic/data';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import { UserRegister } from './views/UserRegister';
import { ProductSearch } from './views/ProductSearch';
import { ProductDetail } from './views/ProductDetail';
import { Landing } from './views/Landing';
import { Page } from './Page';
import { getMatchingEvent } from '../logic/misc';

const LandingPage = () => (
	<Page id="Landing">
		<Landing />
	</Page>
);

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			events: [],
		};

		loadCSV('master-H1.csv').then((e) => {
			this.setState({
				loading: false,
				events: e.map((array) => ({
					name: array[0],
					localId: array[1],
					partnerLogo: array[2],
					eventLogo: array[3],
					tableSheet: array[4],
					backgroundImageUrl: array[5],
					currency: array[6],
					otherCurrency: array[7],
					otherCurrencyRate: array[8],
					feedback: array[9],
					supportEmail: array[10]
				})),
			});
		});
	}

	render() {
		const { events, loading } = this.state;

		if (loading) return <section>Loading...</section>;

		return (
			<Router>
				<Switch>
					<Route path={'/'} exact component={LandingPage} />

					<Route
						path={'/:hash/'}
						exact
						component={({ match }) => {
							const event = getMatchingEvent(match.params.hash, events);

							if (event === null) {
								return (
									<Page id="Landing">
										<Landing
											title="Sorry"
											msg="We can't seem to find the page You're looking for."
										/>
									</Page>
								);
							}

							return (
								<Page id="UserRegister">
									<UserRegister event={event} />
								</Page>
							);
						}}
					/>

					<Route
						path={'/:hash/search'}
						exact
						component={({ match }) => {
							const event = getMatchingEvent(match.params.hash, events);

							if (event === null) {
								return <Redirect to={'/'} />;
							}

							return (
								<Page id="ProductSearch">
									<ProductSearch event={event} />
								</Page>
							);
						}}
					/>

					{/*<Route
						path={'/:eventIndex/'}
						exact
						component={({ match }) => {
							const event = getMatchingEvent(match.params.hash, events);

							if (event === null) {
								return <Redirect to={'/'} />;
							}

							return (
								<Page id="UserRegister">
									<UserRegister event={event} />
								</Page>
							);
						}}
					/>*/}

					<Route
						path={'/:hash/:productCode'}
						exact
						component={({ match }) => {
							const event = getMatchingEvent(match.params.hash, events);

							if (event === null) {
								return <Redirect to={'/'} />;
							}

							return (
								<Page id="ProductDetail">
									<ProductDetail
										event={event}
										productCode={match.params.productCode}
									/>
								</Page>
							);
						}}
					/>

					<Redirect to={'/'} />
				</Switch>
			</Router>
		);
	}
}

export default App;
